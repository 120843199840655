@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap);
.App_shadowed__2a7DD {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); }

body {
  font-family: 'Roboto', Helvetica, sans-serif;
  font-size: 1.5vh;
  width: 100%;
  height: 100%;
  color: #1f1f1f;
  background-color: #f8f8f8;
  box-sizing: border-box; }

li {
  color: #1f1f1f; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

a,
a:link,
a:hover,
a:visited,
a:active {
  text-decoration: none; }

img {
  max-width: 100%; }

ul {
  list-style-type: none; }

.BaseLayout_shadowed__xZ46- {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); }

footer {
  color: #1f1f1f; }

.BaseLayout_dark__3JoGj {
  background-color: #1f1f1f;
  color: #f8f8f8;
  transition: all 400ms; }
  .BaseLayout_dark__3JoGj a, .BaseLayout_dark__3JoGj i, .BaseLayout_dark__3JoGj li {
    color: #f8f8f8;
    transition: color 400ms; }
  .BaseLayout_dark__3JoGj a:visited {
    color: #f8f8f8; }
  .BaseLayout_dark__3JoGj footer {
    color: #f8f8f8; }

.BaseLayout_light__tbwgN {
  background-color: #f8f8f8;
  color: #1f1f1f;
  transition: all 400ms; }

.Navbar_shadowed__1gOva {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); }

nav {
  transition: all 250ms ease; }

.Navbar_active__1o4qU {
  border-bottom: 5px solid;
  border-image-slice: 1; }

li {
  transition: all 250ms ease; }
  li:hover {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
    transition: all 250ms ease; }

.Home_shadowed__GXRih {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); }

h1 {
  font-size: 2rem;
  text-align: center; }
  @media only screen and (min-width: 940px) {
    h1 {
      font-size: 3rem;
      text-align: left; } }

h2 {
  font-size: 1.25rem;
  text-align: center; }
  @media only screen and (min-width: 940px) {
    h2 {
      font-size: 2rem;
      text-align: left; } }

.Home_hand__u4sN9 {
  -webkit-animation-name: Home_wave__1cQtJ;
          animation-name: Home_wave__1cQtJ;
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-transform-origin: 70% 70%;
          transform-origin: 70% 70%;
  display: inline-block; }

a {
  color: #1f1f1f;
  transition: color 200ms ease; }

a:hover {
  color: #c9c9c9;
  transition: color 200ms ease; }

@-webkit-keyframes Home_wave__1cQtJ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg); }
  20% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg); }
  30% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg); }
  40% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg); }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes Home_wave__1cQtJ {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  10% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg); }
  20% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg); }
  30% {
    -webkit-transform: rotate(14deg);
            transform: rotate(14deg); }
  40% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg); }
  50% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg); }
  60% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.About_shadowed__fyXNJ {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); }

.About_pink__1UdUC {
  color: #CA6BE6; }

.About_green__3CngP {
  color: #27C93F; }

.About_skills__pxTAq {
  -webkit-columns: 1;
          columns: 1; }
  @media only screen and (min-width: 940px) {
    .About_skills__pxTAq {
      -webkit-columns: 2;
              columns: 2; } }
  .About_skills__pxTAq li {
    margin: 0;
    line-height: 1.75; }

.Terminal_shadowed__1ry2h {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); }

i {
  padding-left: 0.5rem; }
  i.Terminal_red__2T4U4 {
    color: #FF6057; }
  i.Terminal_amber__2mGcX {
    color: #FFBD2E; }
  i.Terminal_green__UyKp6 {
    color: #27C93F; }

.Terminal_terminal__g0jpl p, .Terminal_terminal__g0jpl li {
  margin: 1rem 0;
  color: #f8f8f8; }

.Terminal_terminal__g0jpl a, .Terminal_terminal__g0jpl a:visited {
  -webkit-animation: Terminal_changeColors__GcVIJ 3s infinite;
          animation: Terminal_changeColors__GcVIJ 3s infinite;
  font-weight: bold; }

@-webkit-keyframes Terminal_changeColors__GcVIJ {
  0% {
    color: #00a47f; }
  33.3% {
    color: #1d91e3; }
  66.6% {
    color: #d419fe; }
  100% {
    color: #00a47f; } }

@keyframes Terminal_changeColors__GcVIJ {
  0% {
    color: #00a47f; }
  33.3% {
    color: #1d91e3; }
  66.6% {
    color: #d419fe; }
  100% {
    color: #00a47f; } }

.Imprint_shadowed__3GYe_ {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); }

.Imprint_pink__3-iZN {
  color: #CA6BE6; }

.Imprint_green__qgF_U {
  color: #27C93F; }

.Imprint_skills__61W9t {
  -webkit-columns: 1;
          columns: 1; }
  @media only screen and (min-width: 940px) {
    .Imprint_skills__61W9t {
      -webkit-columns: 2;
              columns: 2; } }
  .Imprint_skills__61W9t li {
    margin: 0;
    line-height: 1.75; }

.Terminal_shadowed__3DPGe {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4); }

i {
  padding-left: 0.5rem; }
  i.Terminal_red__2aMrU {
    color: #FF6057; }
  i.Terminal_amber__1VuuM {
    color: #FFBD2E; }
  i.Terminal_green__2Ltne {
    color: #27C93F; }

.Terminal_terminal__1XPNV p, .Terminal_terminal__1XPNV li {
  margin: 1rem 0;
  color: #f8f8f8; }

.Terminal_terminal__1XPNV a, .Terminal_terminal__1XPNV a:visited {
  -webkit-animation: Terminal_changeColors__3XAOU 3s infinite;
          animation: Terminal_changeColors__3XAOU 3s infinite;
  font-weight: bold; }

@-webkit-keyframes Terminal_changeColors__3XAOU {
  0% {
    color: #00a47f; }
  33.3% {
    color: #1d91e3; }
  66.6% {
    color: #d419fe; }
  100% {
    color: #00a47f; } }

@keyframes Terminal_changeColors__3XAOU {
  0% {
    color: #00a47f; }
  33.3% {
    color: #1d91e3; }
  66.6% {
    color: #d419fe; }
  100% {
    color: #00a47f; } }

